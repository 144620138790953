/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./redux/store";
import { setAuthToken, setUser } from "./redux/slices/userSlice";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { readLocalUser, readLocalAuthToken } from "./redux/localstore";

const container = document.getElementById("app");
const root = createRoot(container);
const clientId = "207409233511-er9hr243klvcmh6u98hoq8cuchu0tqei.apps.googleusercontent.com";

const user = readLocalUser();
if (user) {
  store.dispatch(setUser(user));
}

const authToken = readLocalAuthToken();
if (authToken) {
  store.dispatch(setAuthToken(authToken));
}

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
