import { createSlice } from "@reduxjs/toolkit";
import { persistLocalAuthToken, persistLocalUser } from "../localstore";

const initialState = {
  info: null,
  auth: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.info = action.payload;
    },
    setUserFromGoogle(state, action) {
      var data = {
        origin: "google",
        name: action.payload.name,
        family_name: action.payload.family_name,
        email: action.payload.email,
        picture: action.payload.picture,
      };
      state.info = data;
      persistLocalUser(data);
    },
    setAuthToken(state, action) {
      state.auth = action.payload;
      persistLocalAuthToken(action.payload);
    },
    clearUser(state) {
      state.info = null;
      state.auth = null;
      localStorage.clear();
    },
  },
});

export const { setUser, setUserFromGoogle, setAuthToken, clearUser } = userSlice.actions;

export default userSlice.reducer;
