import React, { useEffect } from "react";

import axios from "axios";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/basic";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import successImage from "assets/images/success01_write_250.png";


function Basic() {
  const config = require("../../config");
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [sailorCaptcha, setSailorCaptcha] = useState("");
  const [apiWaiting, setApiWaiting] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorSBMessage, setErrorSBMessage] = useState("");


  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=6LezqS0qAAAAALE0BTByrtEEs2iC-5M5qvIdxlme`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute("6LezqS0qAAAAALE0BTByrtEEs2iC-5M5qvIdxlme", { action: "submit" }).then((token) => {
          setSailorCaptcha(token);
        });
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const join = (e) => {
    e.preventDefault();
    if (!sailorCaptcha || sailorCaptcha === "") {
      setErrorSB(true);
      setErrorSBMessage("This Browser (or Private Mode) was not allowed");
      return;
    }
    setApiWaiting(true);
    // get form data
    var data = {
      email: e.target[0].value,
      name: e.target[1].value,
      age: e.target[2].value,
      country: e.target[3].value,
      phone: e.target[4].value,
      capital: e.target[5].value
    };

    // console.log(JSON.stringify(data));

    var api = config.APIURL + "/prospect";

    var headers = {headers: {'X-Recaptcha-Token': sailorCaptcha}};
    axios.post(api, data, headers)
    .then((response) => {
      // console.log("success sent:", response.data);
      setSuccessSB(true);
      setApiResponse(response.data);
      console.log("success sent:", response.data);
    })
    .catch((error) => {
      setErrorSB(true);
      setErrorSBMessage(error.message);
      // console.log("Error sending form:", error);
      console.log("error sent:", JSON.stringify(error));
    });

  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="ProfitSailor"
      content="Hello, world! This is a notification message"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="ProfitSailor"
      content={errorSBMessage}
      dateTime="Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );


  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      {errorSBMessage}
    </MDTypography>
  );

  const renderError = (
    <MDBox mb={8}>
      <MDAlert color="error">
        {alertContent()}
      </MDAlert>
    </MDBox>
  );

  const mainForm = (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Waitlist for New Users
        </MDTypography>

      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form" onSubmit={(e) => join(e)}>
          <MDBox mb={2}>
            <MDInput type="email" label="Email" variant="standard" fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" label="Name" variant="standard" fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="number" label="Age" variant="standard" fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" label="Country" variant="standard" fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" label="Phone" variant="standard" fullWidth />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="number" label="Your Capital (USD)" variant="standard" fullWidth />
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={apiWaiting}>
              join list
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );

  const successComponent = (
    <React.Fragment>
      <MDAlert color="success">
        <MDTypography variant="body2" color="white">
          Success! Now you are on waitlist! Your ID is{" "}
          <MDTypography variant="body2" fontWeight="medium" color="white">
            {apiResponse && apiResponse.data && apiResponse.data.id}
          </MDTypography>
          . We'll keep you updated
        </MDTypography>
      </MDAlert>

      {/* <img src={successImage} alt="ProfitSailor" style={{ position: "relative" }} /> */}

    </React.Fragment>
  );

  return (
    <BasicLayout image={bgImage}>

      {errorSB && renderError}

      { apiResponse && apiResponse.data && apiResponse.success ? successComponent : mainForm}

      {renderSuccessSB}
      {renderErrorSB}
    </BasicLayout>
  );
}

export default Basic;
