// utils/auth.js

export const persistLocalUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const readLocalUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const removeLocalUser = () => {
  localStorage.removeItem("user");
};

export const persistLocalAuthToken = (AuthToken) => {
  localStorage.setItem("AuthToken", AuthToken);
};

export const readLocalAuthToken = () => {
  return localStorage.getItem("AuthToken");
};

export const removeLocalAuthToken = () => {
  localStorage.removeItem("AuthToken");
};
